const Testimonial = ({
  image,
  description,
}: {
  image: string;
  description: string;
}) => (
  <div className="py-20 bg-khamarBondhuGreen">
    <div className="container">
      <div className="flex flex-col items-center gap-16 lg:gap-20 lg:flex-row">
        <div className="lg:w-1/3">
          <img
            src={image}
            alt="doctor"
            className="max-w-xs mx-auto lg:w-full"
          />
        </div>
        <div className="lg:w-2/3">
          <p className="text-khamarBondhuWhite leading-8 font-bold text-lg text-center">
            {description}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonial;
