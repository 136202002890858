import InfoCard from "../atoms/InfoCard";
import Title from "../atoms/Title";

const WhyToUseKhamarBondhu = ({
  title,
  items,
}: {
  title: string;
  items: any[];
}) => (
  <div className="container">
    <div className="lg:max-w-6xl lg:mx-auto py-20 space-y-20">
      <Title className="text-center text-khamarBondhuOliveGreen">{title}</Title>

      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {items.map((item, index) => (
          <InfoCard key={index} image={item.image} text={item.text} />
        ))}
      </div>
    </div>
  </div>
);

export default WhyToUseKhamarBondhu;
