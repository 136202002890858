import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
  start?: boolean;
};
const Paragraph: React.FC<props> = ({
  children,
  className = "",
  start = false,
}) => (
  <h3
    className={classNames(
      "leading-8 font-normal text-2xl",
      className,
      start ? "text-left" : "text-center"
    )}
  >
    {children}
  </h3>
);

export default Paragraph;
