const Footer = () => (
  <div className="bg-khamadBondhuMeroon pb-7 pt-12 mt-56">
    <div className="container space-y-2">
      <div className="border-b-2 border-khamarBondhuWhite" />
      <div className="flex justify-between items-center font-['Lato'] text-khamarBondhuWhite">
        <p className="font-light">
          Copyright © {new Date().getFullYear()} mPower Social Enterprises Ltd.
          All Rights Reserved
        </p>
        <p className="font-light">
          Design & Development By: mPower Social Ltd.
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
