const Campaign = () => (
  <div className="max-w-4xl mx-auto border">
    <div
      className="space-y-6 md:space-y-10 p-8"
      style={{
        background: `url(/assets/img/khamar-campaign-bg.png)`,
        backgroundSize: "cover",
      }}
    >
      <img
        src="/assets/img/khamar-bondhu-campaign-logo.png"
        className="max-w-[150px] lg:max-w-[200px] mx-auto"
        alt="campain logo"
      />
      <p className="text-[#165730] md:leading-8 font-normal md:text-xl text-justify md:text-left font-['Kalpurush']">
        খামারবন্ধু অ্যাপ একজন খামারিকে সার্বক্ষনিক সহকারীর মতোই সাহায্য করবে।
        খামারবন্ধু, খামারের প্রতিটি গরুর আলাদা আলাদা তথ্য সংরক্ষণ থেকে শুরু করে
        সময় মতো টিকা ও কৃমিনাশক প্রয়োগের কথা খামারিকে স্মরণ করিয়ে দিবে, গরুর ওজন
        ও উৎপাদনশীলতা বিবেচনায় প্রতিটি গরুর জন্য প্রয়োজনীয় খাদ্য তালিকা তৈরি করে
        দিবে, খামারবন্ধু ব্যবহার করে খামারি তাঁর আয়-ব্যয়ের হিসাব রাখতে পারবেন
        এবং সর্বোপরি খামার ব্যবস্থাপনা সম্পর্কে একজন খামারি তাঁর জ্ঞানকে যেকোন
        সময়ে ঝালাই করে নিতে পারবেন।
      </p>
    </div>
    <div className="pt-4">
      <img
        src="/assets/img/khamar-campain-diagram.png"
        alt="khamar-campain-diagram"
        className="mx-auto w-full"
      />
    </div>
    <div className="px-4 py-5 lg:py-8 font-['Kalpurush'] justify-around flex items-center flex-col gap-2 lg:gap-4 md:flex-row">
      <p className="text-[#1B7C76] font-bold text-base text-center md:text-xl md:text-left max-w-lg">
        এখনই ডাউনলোড করুন খামার বন্ধু অ্যাপ, ডাউনলোড করতে ডাউনলোড বাটনে ক্লিক
        করুন
      </p>
      <a
        href="https://play.google.com/store/apps/details?id=com.mpower.app.mdairy"
        target="_blank"
        rel="noreferrer"
        className="bg-[#165730] text-white py-3 px-7 text-base md:text-lg rounded-xl font-bold hover:bg-[#165730]/90"
      >
        ডাউনলোড
      </a>
    </div>
  </div>
);

export default Campaign;
