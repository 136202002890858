export type commonProps = {
  title: string;
  description: string;
  image: string;
};
const MOCKS = {
  hero: {
    title: "খামারবন্ধু",
    description:
      "খামারবন্ধু, খামারের প্রতিটি গরুর আলাদা আলাদা তথ্য সংরক্ষণ থেকে শুরু করে সময় মতো টিকা ও কৃমিনাশক প্রয়োগের কথা খামারিকে স্মরণ করিয়ে দিবে, গরুর ওজন ও উৎপাদনশীলতা বিবেচনায় প্রতিটি গরুর জন্য প্রয়োজনীয় খাদ্য তালিকা তৈরি করে দিবে, খামারবন্ধু ব্যবহার করে খামারি তাঁর আয়-ব্যয়ের হিসাব রাখতে পারবেন",
    image: "/assets/img/hero-section.png",
  },
  about: {
    text: "আমাদের দেশে গরুর খামার করার বিষয়টি ক্রমেই জনপ্রিয় হয়ে উঠছে| ঐতিহ্যগতভাবে গ্রামাঞ্চলে যারা খামার করতেন তাদের পাশাপাশি অনেক শহুরে তরুণ গরুর খামার করাকে একটা পেশা হিসেবে নিচ্ছে| এটা খুবই উৎসাহব্যঞ্জক এতে দেশের কর্মসংস্থানে এই খাত যেমন আরও বেশি অবদান রাখছে, তেমনি মানুষের পুষ্টি ঘাটতি নিরসনে রাখছে গুরুত্বপূর্ণ ভ‚মিকা| তবে খামার করার ব্যাপারে সব খামারির অভিজ্ঞতা মসৃন নয়| কারিগরি জ্ঞানের অভাব, খামার সম্পর্কে কোনো রেকর্ড না রাখা, সঠিক পদ্ধতিতে গরুর বৃদ্ধি ও উৎপাদন মনিটরিং করতে না পারার মতো অনেক কারণেই অনেকে খামার করতে এসে আশানুরূপ ফল পান না এবং হতাশ হয়ে পড়েন| খামারিদের এই বাস্তব সমস্যাগুলোর বাস্তব সমাধানের লক্ষ্যে আমরা এনেছি এই খামারবন্ধু অ্যাপ।",
    image: "/assets/img/cow.svg",
  },
  mobileApp: {
    text: "খামারবন্ধু অ্যাপ একজন খামারিকে সার্বক্ষনিক সহকারীর মতোই সাহায্য করবে। খামারবন্ধু, খামারের প্রতিটি গরুর আলাদা আলাদা তথ্য সংরক্ষণ থেকে শুরু করে সময় মতো টিকা ও কৃমিনাশক প্রয়োগের কথা খামারিকে স্মরণ করিয়ে দিবে, গরুর ওজন ও উৎপাদনশীলতা বিবেচনায় প্রতিটি গরুর জন্য প্রয়োজনীয় খাদ্য তালিকা তৈরি করে দিবে, খামারবন্ধু ব্যবহার করে খামারি তাঁর আয়-ব্যয়ের হিসাব রাখতে পারবেন এবং সর্বোপরি খামার ব্যবস্থাপনা সম্পর্কে একজন খামারি তাঁর জ্ঞানকে যেকোন সময়ে ঝালাই করে নিতে পারবেন|",
    image: "/assets/img/app-logo.png",
  },
  info: {
    image: "/assets/img/info.png",
  },
  feature: {
    title: "নিচে খামারবন্ধু অ্যাপের বিভিন্ন ফিচার বর্ণনা করা হলো",
    items: [
      {
        background: "#FFF0C8",
        title: "গরুর প্রোফাইল",
        description:
          "একজন খামারি এই ফিচারটি ব্যবহার করে খামারের প্রতিটি গরুর প্রোফাইল তৈরি ও আপডেট করতে পারবেন। তাতে করে ঐ গরুর অতীতের সকল গরুত্বপূর্ণ তথ্য যেমন, গরু ক্রয় সংক্রান্ত তথ্য, ওজন বৃদ্ধির রেকর্ড, উৎপাদনশীলতা বাড়া-কমার তথ্য, কবে কবে টীকা/কৃমিনাশক দেয়া হয়েছিল তার তথ্য ইত্যাদি এখান থেকেই জেনে যাওয়া যাবে। ফলাফলস্বরূপ, খামারের কোন গরুটা খামারের জন্য বেশি আয় নিয়ে আসছে বা কোন গরুটার জন্য খামারের খরচ বেড়ে যাচ্ছে, সে সম্পর্কে খামারি একটি স্বচ্ছ ধারণা পাবেন।",
        image: "/assets/img/feature-1.png",
      },
      {
        background: "#FFF9C8",
        title: "গরুর ওজন নির্ণয়",
        description:
          "যেকোন গরুর ওজন মাপতে একজন খামারি এই ফিচারটি ব্যবহার করতে পারবেন। এখানে গরুর দৈর্ঘ্য ও বেড় অনুযায়ী বৈজ্ঞানিক সূত্র মোতাবেক ওজন মাপার ব্যবস্থা করা আছে।",
        image: "/assets/img/feature-2.png",
      },
      {
        background: "#FFDCC8",
        title: "খামার ব্যবস্থাপনা",
        description:
          "একটি খামার সুষ্ঠুভাবে পরিচালনা করার জন্য একজন খামারির যেসব বিষয় সম্পর্কে জ্ঞান থাকা দরকার তার সবকিছুই তিনি পাবেন এই ফিচারে। বিভিন্ন প্রামাণিক কনটেন্ট থেকে খামারির জন্য প্রয়োজনীয় তথ্য, খামারির বোঝার উপযোগী করে ও সহজবোধ্যভাবে এখানকার কনটেন্টগুলো তৈরি করা হয়েছে।",
        image: "/assets/img/feature-3.png",
      },
      {
        background: "#F6EAE1",
        title: "আয়-ব্যয়ের তথ্য",
        description:
          "এই ফিচার ব্যবহার করে একজন খামারি খুব সহজেই খামার থেকে তাঁর আয় ও খামারের পিছনে তাঁর ব্যয়ের তথ্য সংরক্ষণ করতে পারবেন। তার ফলে স্বল্প, মাঝারি ও দীর্ঘ মেয়াদে খামার থেকে খামারি পর্যাপ্ত লাভবান হচ্ছেন কিনা তা তিনি জেনে যাবেন।",
        image: "/assets/img/feature-4.png",
      },
      {
        background: "#FFECC8",
        title: "রেশন ক্যালকুলেটর",
        description:
          "নাম থেকে যেমনটা বোঝা যাচ্ছে, একটি গরুর দৈনিক আদর্শ খাবারের পরিমাণ নিরুপন করে দিবে এই রেশন ক্যালকুলেটর। টীকা/কৃমিনাশন: গরুর টীকা/কৃমিনাশক সেবনের তথ্য সঠিকভাবে অ্যাপে সংরক্ষণ করলে অ্যাপ থেকে স্বয়ংক্রিয়ভাবে খামারিকে পরবর্তী টিকার সময়ের আগে আগে এলার্ট প্রদান করবে।",
        image: "/assets/img/feature-5.png",
      },
      {
        background: "#F2F6E1",
        title: "টীকা/কৃমিনাশন",
        description:
          "এই ফিচার ব্যবহার করে একজন খামারি খুব সহজেই খামার থেকে তাঁর আয় ও খামারের পিছনে তাঁর ব্যয়ের তথ্য সংরক্ষণ করতে পারবেন। তার ফলে স্বল্প, মাঝারি ও দীর্ঘ মেয়াদে খামার থেকে খামারি পর্যাপ্ত লাভবান হচ্ছেন কিনা তা তিনি জেনে যাবেন",
        image: "/assets/img/feature-6.png",
      },
    ],
  },
  whyToUseKhamarBondhu: {
    title: "একজন খামারি হিসেবে আপনি কেন খামারবন্ধু ব্যবহার করবেন?",
    items: [
      {
        text: "উচ্চ মানের পশু চিকিৎসা এখন হাতের মুঠোয়",
        image: "/assets/img/bag-icon.svg",
      },
      {
        text: "খামারবন্ধু একটি অন-ডিমান্ড পরিষেবা 24/7 উপলব্ধ",
        image: "/assets/img/time-icon.svg",
      },
      {
        text: "ভবিষ্যতে রেফারেন্স জন্য প্রেসক্রিপশন রেকর্ড করা হবে",
        image: "/assets/img/prescription-icon.svg",
      },
      {
        text: "প্রথাগত পশুচিকিৎসা সেবা পাওয়ার জন্য খরচের একটি ভগ্নাংশ",
        image: "/assets/img/money-icon.svg",
      },
    ],
  },
  whyKhamarBondhu: {
    title: "কেন খামারবন্ধু?",
    sections: [
      {
        background:
          "linear-gradient(30.27deg, #ABF5FF 19.54%, rgba(171, 213, 226, 0) 98.21%)",
        image: "/assets/img/farmer-hero-image.svg",
        item: {
          title: "খামারি",
          list: [
            "দেশের সেরা পশু চিকিৎসকদের বিশেষজ্ঞ মতামত",
            "চাহিদা অনুযায়ী 24/7 পরিষেবা",
            "ভবিষ্যতের রেফারেন্সের জন্য রেকর্ডকৃত প্রেসক্রিপশন",
            "তুলনামূলকভাবে কম খরচে",
            "স্বাস্থ্যকর গবাদি পশু, উচ্চ উৎপাদনশীলতা এবং অধিক আয়",
          ],
        },
      },
    ],
  },
  meetOurDoctors: {
    title: "আমাদের পশু চিকিৎসকদের তালিকা",
    items: [
      {
        image: "/assets/img/veterinarians/Dr. Kaosar.png",
        name: "DR. MIR KAUSER HOSSAIN",
        designation: "Veterinary Surgeon",
        email: "Jhenaidah",
        mobile: "BVC Reg no: 3991",
      },
      {
        image: "/assets/img/veterinarians/DR. Jahid.jpg",
        name: "DR. MD. JAHID-AL-HASAN",
        designation: "Veterinary Surgeon",
        email: "PABNA",
        mobile: "BVC Reg no: 4855",
      },
      {
        image: "/assets/img/veterinarians/DR. Tapu.jpg",
        name: "DR. TAPU RAYHAN",
        designation: "Deputy General Manager",
        email: "PRAN-RFL Animal Feed Division",
        mobile: "BVC Reg no: 4906",
      },
      {
        image: "/assets/img/veterinarians/DR. Mumin.png",
        name: "Dr. Md. Mominul Islam",
        designation: "Senior Market Extension Officer",
        email: "Quality Feeds Limited",
        mobile: "BVC Reg no: 5250",
      },
      {
        image: "/assets/img/veterinarians/DR. Forhad 1.png",
        name: "DR. MD. FARHAD HOSSAIN",
        designation: "Ex Director, Research, Training and Evaluation",
        email: "Department of Livestock Services, Dhaka, Bangladesh. ",
        mobile: "BVC Reg no: 0299",
      },
      {
        image: "/assets/img/veterinarians/DR. SADDAM.png",
        name: "DR. MD. SADDAM HOSSAIN",
        designation: "Managing Director",
        email: "Birds and Pet Animal Clinic",
        mobile: "BVC Reg no: 4855",
      },
      {
        image: "/assets/img/veterinarians/Dr. Tareq 1.png",
        name: "DR. MD. TARQUL ISLAM",
        designation: "Operation Coordinator",
        email: "mPower Social Enterprise Limited",
        mobile: "BVC Reg no: 4771",
      },
      {
        image: "/assets/img/veterinarians/DR. Surovi.png",
        name: "DR. JANNATUL FERDOUS",
        designation: "Senior Lab Technicians",
        email: "Nahar Agro Industries Limited",
        mobile: "BVC Reg no: 4464",
      },
    ],
  },
  testimonial: {
    image: "/assets/img/doctor.png",
    description:
      "এই ফিচারটি ব্যবহার করে খামারি একজন রেজিস্টার্ড ভেটেরিনারিয়ানের কাছ থেকে ভিডিও বা অডিও কলের মাধ্যমে গরুর অসুস্থতায় চিকিৎসা সেবা নিতে পারবেন| খামারি একটি তালিকা থেকে তাঁর পছন্দ অনুযায়ী একজন ভেটেরিনারিয়ান খুঁজে বের করবেন এবং উক্ত ভেটেরিনারিয়ানের সার্ভিস ফি অ্যাপের মাধ্যমে পরিশোধ করবেন| তারপর ভেটেরিনারিয়ান উক্ত খামারিকে কল প্রদান করবেন এবং রোগ সম্পর্কে আরও বিস্তারিত জেনে অ্যাপের মাধ্যমেই একটি ডিজিটাল প্রেসক্রিপশন খামরিকে পাঠিয়ে দিবেন| এই সার্ভিসটি যদিও এখনো চালু হয়নি, তবে অচিরেই এই সার্ভিসটা নিয়ে আসার ব্যাপারে আমরা কাজ করছি|",
  },
};

export default MOCKS;
