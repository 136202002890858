import Paragraph from "../atoms/Paragraph";

const About = ({ text, image }: { text: string; image: string }) => (
  <div id="about">
    <div className="py-10 relative">
      <img src={image} alt="cow" className="mx-auto" />
      <div className="h-[210px] bg-[#E8FFFF] -mt-64" />
    </div>
    <div className="container py-20">
      <Paragraph className="text-center">{text}</Paragraph>
    </div>
  </div>
);

export default About;
