import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
  secondary?: boolean;
};
const Title: React.FC<props> = ({
  children,
  className = "",
  secondary = false,
}) => (
  <h3
    className={classNames(
      "text-2xl lg:text-4xl font-semibold",
      className,
      secondary ? "text-khamarBondhuTitleBlack" : "text-khamadBondhuMeroon"
    )}
  >
    {children}
  </h3>
);

export default Title;
