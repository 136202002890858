import Title from "../atoms/Title";
import FeatureCard from "../molecules/FeatureCard";

const Feature = ({ title, items }: { title: string; items: any[] }) => (
  <div className="py-20 space-y-16 container" id="feature">
    <Title className="text-center" secondary>
      {title}
    </Title>
    <div className="grid lg:grid-cols-2 gap-2">
      {items.map((item, index) => (
        <FeatureCard key={index} {...item} />
      ))}
    </div>
  </div>
);

export default Feature;
