import About from "../components/molecules/About";
import Hero from "../components/molecules/Hero";
import Info from "../components/molecules/Info";
import MeetOurDoctors from "../components/molecules/MeetOurDoctors";
import MobileApp from "../components/molecules/MobileApp";
import Testimonial from "../components/molecules/Testimonial";
import WhyToUseKhamarBondhu from "../components/molecules/WhyToUseKhamarBondhu";
import Feature from "../components/organisms/Feature";
import WhyKhamarBondhu from "../components/templates/WhyKhamarBondhu";
import MOCKS from "../data/mocks";
import Layouts from "../layouts/Layouts";
const {
  hero,
  about,
  mobileApp,
  info,
  feature,
  whyToUseKhamarBondhu,
  whyKhamarBondhu,
  meetOurDoctors,
  testimonial,
} = MOCKS;

const Home = () => (
  <Layouts>
    <Hero {...hero} />
    <About {...about} />
    <MobileApp {...mobileApp} />
    <Info {...info} />
    <Feature {...feature} />
    <WhyToUseKhamarBondhu {...whyToUseKhamarBondhu} />
    <WhyKhamarBondhu {...whyKhamarBondhu} />
    <MeetOurDoctors {...meetOurDoctors} />
    <Testimonial {...testimonial} />
  </Layouts>
);

export default Home;
