import { commonProps } from "../../data/mocks";
import Paragraph from "../atoms/Paragraph";
import Title from "../atoms/Title";

const Hero = ({ title, description, image }: commonProps) => (
  <div className="container" id="home">
    <div className="flex flex-col lg:flex-row items-center py-16 md:py-28 gap-20 lg:gap-0">
      <div className="space-y-12 order-2 lg:order-1 lg:w-1/2">
        <Title className="text-center">{title}</Title>
        <Paragraph>{description}</Paragraph>
      </div>
      <div className="order-1 lg:order-2 lg:w-1/2">
        <img src={image} alt="hero" />
      </div>
    </div>
  </div>
);

export default Hero;
