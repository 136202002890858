import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
};
const SubTitle: React.FC<props> = ({ children, className = "" }) => (
  <h4
    className={classNames(
      "text-xl lg:text-2xl font-bold text-khamadBondhuMeroon",
      className
    )}
  >
    {children}
  </h4>
);

export default SubTitle;
