export const navigation = {
  menu: [
    {
      title: "Home",
      path: "#home",
    },
    {
      title: "About",
      path: "#about",
    },
    {
      title: "Feature",
      path: "#feature",
    },
  ],
  phone: "01987-6543-21",
};
