import React, { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";
type props = {
  children: ReactNode;
};

const Layouts: React.FC<props> = ({ children }) => (
  <>
    <div className="fixed w-full shadow-lg z-50 top-0">
      <Header />
    </div>
    <div className="pt-20 lg:pt-[119px]">
      <div>{children}</div>
      <Footer />
    </div>
  </>
);

export default Layouts;
