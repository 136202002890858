const MobileApp = ({ text, image }: { text: string; image: string }) => (
  <div className="pb-24 lg:py-36 bg-khamadBondhuMeroon lg:bg-transparent">
    <div className=" relative">
      <div className="absolute top-0 left-0 -z-10 w-full lg:flex justify-center items-center hidden">
        <img
          src="/assets/img/mobile-app-background.svg"
          className="w-full mx-auto"
          alt="app-background"
        />
      </div>
      <div className="flex flex-col lg:flex-row items-center gap-10 lg:gap-0  container">
        <div className="space-y-12 order-2 lg:order-1 lg:w-1/2">
          <p className="text-khamarBondhuWhite leading-8 text-center font-semibold text-lg">
            {text}
          </p>
        </div>
        <div className="order-1 lg:order-2 lg:w-1/2 mt-20">
          <img
            src={image}
            className="max-w-xs lg:max-w-md mx-auto"
            alt="app-logo"
          />
        </div>
      </div>
    </div>
  </div>
);

export default MobileApp;
