import SubTitle from "../atoms/SubTitle";

const FeatureCard = ({
  background,
  image,
  title,
  description,
}: {
  background: string;
  image: string;
  title: string;
  description: string;
}) => (
  <div style={{ background }} className="px-10 py-20 space-y-20">
    <img src={image} alt="feature" className="mx-auto max-w-[192px]" />
    <div className="space-y-5 text-center md:text-left md:pl-9">
      <SubTitle>{title}</SubTitle>
      <p className="leading-8 font-normal text-lg">{description}</p>
    </div>
  </div>
);

export default FeatureCard;
