import { useState } from "react";
import { navigation } from "../routes/navigation";
import classNames from "../utils/classes";

const Header = () => {
  const [isActive, setIsActive] = useState("#home");
  const [open, setOpen] = useState(false);
  const list = navigation.menu.map((item) => (
    <li key={item.path}>
      <a
        className={classNames(
          "block border-2 py-1 px-5 rounded-md hover:bg-khamarBondhuWhite hover:text-khamadBondhuMeroon hover:border-transparent cursor-pointer",
          isActive === item.path
            ? "text-khamadBondhuMeroon border-transparent bg-khamarBondhuWhite"
            : "text-khamarBondhuWhite",
          "font-['ABeeZee']"
        )}
        href={item.path}
        onClick={() => {
          setIsActive(item.path);
          setOpen(false);
        }}
      >
        {item.title}
      </a>
    </li>
  ));

  return (
    <div className={classNames("bg-khamarBondhuDarktGreen lg:block")}>
      <div className="flex justify-between items-center py-2 lg:py-0 lg:h-[119px] container">
        <div>
          <img
            loading="lazy"
            src="/assets/img/khamarBondhu-logo.png"
            alt="mPower logo"
            className="w-[60px] lg:w-[74px]"
          />
        </div>
        <ul className="lg:flex items-center lg:space-x-16 space-y-4 lg:space-y-0 text-center hidden">
          {list}
        </ul>
        <div className="flex items-center space-x-4 lg:space-x-0">
          <img
            src="/assets/img/mpower-logo.png"
            className="w-[80px] lg:w-[95px]"
            alt="mPower logo"
          />
          <button
            className="lg:hidden pr-2"
            onClick={() => setOpen((prev: any) => !prev)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 block lg:hidden"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </div>
      {open && (
        <div className="container border-t">
          <ul className="lg:hidden space-y-4 lg:space-y-0 text-center block max-w-xs mx-auto pt-10 pb-10">
            {list}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
